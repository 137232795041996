@import '../../../scss/main.scss';

.syp {
    &__heading {
        margin-top: 9rem;
        margin-bottom: 5rem;
    }
    &__container {
        min-height: 80rem;
        font-size: $font-size-regular-3;
        margin-bottom: 0rem;
        padding-bottom: 0%;
    }
    &__text {
        text-align: center;
        color: $color-grey-light-2;
        font-size: $font-size-regular-4;
        letter-spacing: 0.05rem;
        margin-bottom: 5rem;
    }
    &__svg {
        margin: 2rem 8rem;
    }
    &__buttons {
        margin: 4rem 0 !important;
       
    }
    &__button {
        letter-spacing: 0.2rem !important;
        padding: 1rem 3rem !important;
    }
}
