@import "../../scss/main.scss";

.faq__sections {
  color: $color-grey-dark-3;
  margin-bottom: 4rem;
  padding: 1rem 0;

  &--selected {
    border-left: 0.2rem solid $color-primary;
    border-radius: 12px;
  }
  &--container {
    cursor: pointer;
    margin-bottom: 4rem;
    padding-left: 1rem;
    transition: all 0.2s;
  }
  &--heading {
    font-size: $font-size-regular-2;
    font-weight: $font-weight-bold;
    color: $color-grey-dark-2;
  }
  &--details {
    font-size: $font-size-small-3;
  }
}

@media only screen and (max-width: 600px) {
  .faq__sections {
    &--supercontainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      transition: all 0s;
    }
    &--selected {
      border: 0.2rem solid $color-primary !important;
    }
    &--container {
      width: 45vw;
      cursor: pointer;
      margin: 2.5rem 0;
      padding: 1.8rem;
      transition: all 0s;
      text-align: center;
      //box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
    }
    &--details {
      margin-top: 1rem;
      font-size: 1.3rem;
    }
  }
}
.more_ques{
  width: fit-content;
  margin-bottom: 8rem;
  text-align: center;

  font-size: 2rem;
  margin-left: 1rem;
  @media only screen and (max-width: 600px) {
    text-align: start;
    }
 
}