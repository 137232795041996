@import "../../scss/main.scss";

.contact {
  &__group {
    margin-bottom: 2rem;
  }

  &__input {
    background-color: $color-grey-light-1 !important;
    color: $color-grey-dark-1 !important;

    font-size: $font-size-regular-2 !important;
    border: 0 !important;
    padding: 2rem !important;
    padding-top: 2.5rem !important;
    padding-bottom: 1rem !important;
    letter-spacing: 0.1rem;
    border-radius: 0.5rem !important;
    &:focus {
      box-shadow: none !important;
    }
    &::placeholder {
      color: $color-grey-light-2 !important;
      font-size: $font-size-regular-2;
      letter-spacing: 0.1rem;
    }
  }
  &__input_drop {
    background-color: $color-grey-light-1 !important;
    color: $color-grey-dark-1 !important;

    font-size: $font-size-regular-2 !important;
    border: 0 !important;
    padding: 1rem !important;
    padding-top: 0.8rem !important;
    padding-bottom: 1rem !important;
    letter-spacing: 0.1rem;

    border-radius: 0.5rem !important;
    &:focus {
      box-shadow: none !important;
    }
    &::placeholder {
      color: $color-grey-light-2 !important;
      font-size: $font-size-regular-2;
      letter-spacing: 0.1rem;
    }
  }
  &__label {
    color: $color-grey-light-2 !important;
    font-size: $font-size-regular-2 !important;
    padding-left: 2rem !important;
    letter-spacing: 0.1rem;
  }

  &__anchor {
    margin-bottom: 5rem;
    margin-top: 3rem !important;
  }
  &__button {
    font-size: $font-size-regular-2;
    color: $color-white;
    background-color: $color-primary;
    text-decoration: none;
    border-radius: 0.5rem;
    padding: 1rem 4rem;
    margin: 0.5rem 0;
    letter-spacing: 0.2rem;
  }
}

.react-select__value-container {
  font-size: $font-size-regular-2 !important;
}
