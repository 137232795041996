@import "../../scss/main.scss";
.integration {
  &__supercontainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__container {
    width: 80vw;
    height: 60vh;
    background-color: #cccbcb;
    margin-bottom: 6rem;
    color: white;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    text-align: center;
    letter-spacing: 0.07rem;
    font-weight: 700;
  }
}
