// Base Colors
$color-primary: #3ae2ed;
$color-secondary: #ffd654;

// Dark
$color-grey-dark-1: #707070;
$color-grey-dark-2: #2c2c2c;
$color-grey-dark-3: #1d1d1d;

// Sapphire
$color-sapphire: #3e5a7a;

// Light
$color-grey-light-1: #eff2f3;
$color-grey-light-2: #9ba1a3;

// Common
$color-white: #fff;
$color-black: #000;

// Font Size
$font-size-small-1: 0.6em;
$font-size-small-2: 0.8rem;
$font-size-small-3: 1rem;
$font-size-regular-1: 1.2rem;
$font-size-regular-2: 1.4rem;
$font-size-regular-3: 1.6rem;
$font-size-regular-4: 1.8rem;
$font-size-large-1: 2rem;
$font-size-large-2: 4rem;
$font-size-large-3: 5rem;
$font-size-large-4: 6rem;
$font-size-large-5: 10rem;

$font-size-head: 3rem;
// Font Weight
$font-weight-regular: 400;
$font-weight-bold: 700;

// Shadows
$shadow-light: 0 0 0.75rem 0.2rem rgba(0, 0, 0, 0.1);

// Line
$line-1: 0.1rem solid rgba(0, 0, 0, 0.125);
