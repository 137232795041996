@import "../../scss/main.scss";

.navigation {
  margin: 1rem 0;
  letter-spacing: 0.2rem;
  font-size: $font-size-small-3;
  &__container {
    margin-top: -1rem !important;
  }
  &__logo {
    border: none;
    outline: 0;
    margin-bottom: 1rem;
  }
  &__text {
    margin-top: 1.5rem;
    align-self: flex-end;
  }
  &__list {
    padding: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
  &__item {
    margin: 0 1.5rem;
    padding: 0.5rem;
    color: $color-grey-dark-3;
    z-index: 100;
    // font-weight: $font-weight-bold;
  }
}
.navBack {
  background-color: #3e5a7a;
  margin: -1rem 0;
  margin-top: -1rem !important;
}

@media only screen and (max-width: 600px) {
  .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;
    z-index: 999;
    //position: fixed;
    &__container {
      height: 100%;
      width: 100%;

      &--link {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        img {
          height: 20%;
          width: 38%;
          margin-right: 4.5rem;
        }
      }
    }

    &__menu {
      background-color: white;
      width: 70vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      //justify-content: center;
      border-radius: 8px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
      position: fixed;
      bottom: 0;
      left: -100%;
      transition: 850ms;
      z-index: 999;
    }
    &__headerItem {
      display: flex;
      margin-top: 2rem;
      justify-content: start;
      align-items: center;
      &--logo {
        width: 100%;
        height: 100%;
        img {
          width: 65%;
          height: 100%;
          margin-left: 3.5rem;
          //margin-top: 0.3rem;
        }
      }
    }
    &__menuIcon {
      margin-left: 1rem;
      //z-index: 3;
    }
    &__menu.active {
      left: 0;
      transition: 350ms;
      display: flex;
      flex-direction: column;
      z-index: 999;
    }
    &__menuItems {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 4rem;
    }
    &__item {
      display: flex;
      //flex-direction: column;
      //width: 100%;
      justify-content: center;
      align-items: center;
      margin: 2rem 0 0 0.5rem;
      padding: 0;
      font-size: 1.5rem;
      &--first {
        padding: 3.5rem 0 1rem 7rem;
      }
      &--products {
        display: flex;
        margin: 0.5rem 1.2rem;
        width: 185px;
        justify-content: space-between;
        margin-top: 3rem;

        &__container {
          display: flex;
          flex-direction: column;
        }
        &__list {
          padding-left: 2.5rem;
          &--sapphire {
            &__heading {
              font-weight: 400;
            }
            &__message {
              font-size: 1.3rem;
              padding-left: 1rem;
            }
          }
        }
      }
    }
  }

  .navBack {
    background-color: #3e5a7a;
    margin: -1rem 0;
    margin-top: -1rem !important;
    padding: 2rem 0.3rem;
  }
}
