@import "../../../scss/main";

.sapphire-preview {
  background-color: $color-sapphire;
  color: $color-white;
  position: relative;
  z-index: -1;
  &__container {
    min-height: 60rem;
    padding-left: 12rem;
  }
  &__heading {
    margin-top: 4rem;
    font-size: $font-size-large-4;
    font-weight: $font-weight-bold;
    padding-bottom: 1.25rem;
    letter-spacing: 0.1rem;
    z-index: -1 !important;
  }
  &__text {
    padding-right: 5rem;
    font-size: $font-size-regular-3;
    padding-bottom: 2rem;
    letter-spacing: 0.1rem;
  }
  &__lm {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 !important;
    margin-bottom: 2rem;
  }
  &__link {
    font-size: $font-size-regular-2;
    padding-left: 0.75rem !important;
  }
  &__img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 80rem;
    img {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 600px) {
  .sapphire-preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $color-sapphire;
    color: $color-white;
    //position: relative;
    &__container {
      z-index: 7777;
      //margin-bottom: 80rem;
      text-align: center;
      min-height: 100%;
      padding-left: 0rem;
    }
    &__headingContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &__heading {
      margin-top: 4rem;
      font-size: $font-size-large-4;
      font-weight: $font-weight-bold;
      padding-bottom: 1.25rem;
      letter-spacing: 0.1rem;
      text-align: center;
    }
    &__text {
      padding-right: 0rem;
      font-size: $font-size-regular-2;
      padding-bottom: 2rem;
      letter-spacing: 0.1rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      //margin-left: 8.4rem;
    }
    &__textContainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__lm {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
      margin-bottom: 2rem;
      text-align: center;
      //margin-left: 12rem;
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $font-size-regular-2;
      padding-left: 0.75rem !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__img {
      //position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end !important;
      width: 100vw;
      height: 45%;
      //margin-top: 2rem;
      img {
        width: 95%;
        height: 87%;
        //margin-top: 5.5rem;
        //width: fit-content;
      }
    }
  }
  .sapphire-preview-subdiv {
    z-index: 7777;
    margin-bottom: 55rem;
    height: 0vh;
  }
}
