@import "../../scss/main";

.footer {
  background-color: $color-grey-dark-2;
  color: $color-white;

  &__container {
    min-height: 32rem;
    padding-top: 6rem;
    padding-bottom: 1rem;
  }
  &__heading {
    font-size: $font-size-regular-1;
    font-weight: $font-weight-bold;
    padding-bottom: 2rem;
    letter-spacing: 0.25em;
    text-transform: uppercase;
  }
  &__item {
    margin: 1rem 0;
  }

  &__link {
    font-size: $font-size-regular-1;
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 80rem;
    img {
      width: 100%;
    }
  }
  &__form {
    margin: 1rem 0;
  }
}

.footer {
  &__social {
    margin-bottom: 4rem;
    &--link {
      width: 3.5rem;
      /*&-youtube {
        width: 2.5rem;
      }*/
    }
    &--svg {
      width: 2.3rem;
    }
    &--svgyoutube {
      width: 2.8rem;
    }
  }
  &__container {
    min-height: 32rem;
    padding-top: 6rem;
    padding-bottom: 1rem;
  }
  &__subscribe {
    padding: 1rem 0;
    &--input {
      width: 22.5rem;
      &::placeholder {
        color: $color-white;
        letter-spacing: 0.075rem;
      }
      letter-spacing: 0.1rem;
      font-size: $font-size-regular-1;
      color: $color-white;
      background-color: transparent;
      padding: 0.5rem 0;
      padding-bottom: 0.25rem;
      border-bottom: 0.25rem solid $color-white;
    }
    &--button {
      &:link,
      &:visited,
      &:hover &:active {
        font-size: $font-size-small-3;
        color: $color-white;
        border: 0.25rem solid $color-white;
        text-decoration: none;
        border-radius: 2rem;
        padding: 0.5rem 2rem;
        letter-spacing: 0.075rem;
      }
    }
  }

  &__copyright {
    color: $color-grey-light-2;
    font-size: $font-size-regular-1;
    letter-spacing: 0.05rem;
  }
  &__extras {
    z-index: 100;
    &--icon {
      font-size: $font-size-small-3;
      color: $color-grey-light-2;
      padding: 0 1rem;
    }
    &--link {
      font-size: $font-size-small-3 !important;
      text-transform: uppercase;
    }
  }
}
@media only screen and (max-width: 600px) {
  .footer {
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__menu {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 2rem;
    }
    &__subcontainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
    &__rightSide {
      display: flex;
      flex-direction: column;
      margin-top: 3rem;
      width: 100%;
      padding-left: 2rem;
    }
    &__social {
      display: flex;
      flex-direction: row;
      //width: 40%;
      &--svg {
        margin-left: 2rem;
        width: 2.3rem;
      }
      &--svgyoutube {
        width: 2.8rem;
      }
    }
    &__formContainer {
      padding-left: 2rem;
      padding-right: 0.5rem;
      margin-top: 2rem;
      width: 97vw;
    }
    &__extras {
      display: flex;
      justify-content: space-between;
      margin-right: 1.2rem;
      z-index: 1;
      &--icon {
        font-size: $font-size-small-3;
        color: $color-grey-light-2;
        padding: 0 0rem;
        margin: 0 1rem;
      }
      &--link {
        font-size: $font-size-small-2 !important;
        text-transform: uppercase;
      }
    }
    &__headingFollowUs {
      font-size: $font-size-regular-2;
      font-weight: $font-weight-bold;
      padding-bottom: 0rem;
      letter-spacing: 0.25em;
      text-transform: uppercase;
    }
    &__heading {
      font-size: $font-size-regular-1;
      font-weight: $font-weight-bold;
      padding-bottom: 0rem;
      letter-spacing: 0.25em;
      margin-bottom: 0.75rem;
      text-transform: uppercase;
      &--Products {
        margin-top: 0.7rem;
      }
      &--arrow--Products {
        margin-top: 0.5rem;
        transition: 500ms;
      }
      &--arrow--Support {
        margin-top: 1.4rem;
        transition: 500ms;
      }
      &--arrow--Company {
        margin-top: 1.2rem;
        transition: 500ms;
      }
      &--Support {
        margin-top: 2rem;
        transition: 500ms;
      }
      &--Company {
        margin-top: 2rem;
        transition: 500ms;
      }
    }
    &__headingContainer {
      display: flex;
      justify-content: space-between;
      width: 88vw;
    }
    /*&__headingFollowUs {
      width: 40vw;
    }*/
    &__item {
      margin: 0rem 0;
    }
    &__followUs {
      display: flex;
      justify-content: flex-start;
      //width: 100%;
    }
    &__subscribe {
      padding: 1.5rem 0 0 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      &--inputContainer {
        width: 60%;
      }
      &--input {
        //width: 100%;
        letter-spacing: 0.1rem;
        font-size: $font-size-regular-1;
        color: $color-white;
        background-color: transparent;
        padding: 0rem 0 0 0;
        padding-bottom: 0.25rem;
        width: 100%;
        border-bottom: 0.25rem solid $color-white;
      }
      &--button {
        &:link,
        &:visited,
        &:hover &:active {
          font-size: $font-size-small-3;
          color: $color-white;
          border: 0.25rem solid $color-white;
          text-decoration: none;
          border-radius: 2rem;
          padding: 0.5rem 1rem;
          letter-spacing: 0.075rem;
        }
      }
      &--buttonContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        //margin-left: 1.2rem;
        //width: 20%;
      }
    }
    &__footer {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
    &__copyright {
      margin-left: 2rem;
    }
  }
}
