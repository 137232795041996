@import "../../scss/main.scss";

.landing {
  color: $color-grey-dark-1;

  &__container {
    min-height: 60rem;
  }
  &__heading {
    font-size: $font-size-large-2;
    font-weight: $font-weight-bold;
    padding-bottom: 1.25rem;
    letter-spacing: 0.1rem;
  }
  &__span {
    color: $color-primary;
  }
  &__text {
    padding-right: 5rem;
    font-size: $font-size-regular-3;
    padding-bottom: 4rem;
    letter-spacing: 0.05rem;
  }
  &__sft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 2rem;
  }
  &__link {
    font-size: $font-size-regular-2;
    font-weight: 600;
    text-decoration: none;
    background-color: #3ae2ed;
    letter-spacing: 0.05rem;
    cursor: pointer;
    padding: 1.5rem;
  }
  &__compare {
    margin-left: 7.5rem;
    margin-right: -7.5rem;
  }
  .landing__link a {
    text-decoration: none;
    color: #fff;
  }
  @media only screen and (max-width: 600px) {
    &__heading {
      font-size: $font-size-large-2;
      font-weight: $font-weight-bold;
      padding-bottom: 1.25rem;
      letter-spacing: 0.1rem;
      text-align: center;
      margin-top: 3rem;
    }
    &__sft {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 2rem;
    }
    &__text {
      padding-right: 0rem;
      font-size: $font-size-regular-2;
      padding: 0.8rem;
      letter-spacing: 0.05rem;
      margin-bottom: 3rem;
      margin-top: 1rem;
      text-align: center;
    }
    &__compare {
      margin-left: 0rem;
      margin-right: 0rem;
    }
  }
}
