@import "../../scss/main";

.product__dropdown {
  &--button {
    text-transform: uppercase;
    //font-weight: $font-weight-bold !important;
    background-color: transparent !important;
    border: 0 !important;
    outline: none !important;
    color: $color-grey-dark-3 !important;

    letter-spacing: 0.2rem !important;
    padding: 1rem 2rem !important;
    margin: 0.5rem 0 !important;
    padding-left: 2.5rem !important;
    &:focus {
      box-shadow: none !important;
    }
  }
  // the below part (buttonSapphire)only for making product text white in sapphire page at header
  &--buttonSapphire {
    text-transform: uppercase;
    //font-weight: $font-weight-bold !important;
    background-color: transparent !important;
    border: 0 !important;
    outline: none !important;
    color: $color-white !important;

    letter-spacing: 0.2rem !important;
    padding: 1rem 2rem !important;
    margin: 0.5rem 0 !important;
    padding-left: 2.5rem !important;
    &:focus {
      box-shadow: none !important;
    }
  }
  &--menu {
    box-shadow: $shadow-light;
    border: $line-1 !important;
    margin-top: 1rem !important;
    padding: 1.5rem !important;
    border-radius: 0.5rem !important;
  }
  &--heading {
    font-size: $font-size-regular-1 !important;
    font-weight: $font-weight-bold !important;
    color: $color-grey-dark-3 !important;
    letter-spacing: 0.2rem !important;
    text-transform: capitalize !important;
    &:hover,
    &:active {
      background: transparent !important;
    }
  }
  &--detail {
    font-size: $font-size-regular-1 !important;
    letter-spacing: 0.1rem !important;
    color: $color-grey-dark-1 !important;
    text-transform: none;
    &:hover,
    &:active {
      background: transparent !important;
    }
  }
}
