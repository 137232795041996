@import "../../scss/main.scss";

.features {
  margin: 3rem 0;
  padding: 5rem 0;
  color: $color-grey-dark-1;
  text-align: center;
  &__item {
    height: 100%;
    padding: 1rem 0;
    margin: 0 0.5rem;
    box-shadow: $shadow-light;
    width: 100%; // by RS
  }
  &__svg {
    min-height: 20rem;
    padding: 0.5rem 0;
    width: 19.5rem;

    &--smallest {
      width: 12.5rem;
    }
    &--small {
      width: 15rem;
    }
    &--smallr {
      width: 13rem;
    }
  }

  &__heading {
    font-size: $font-size-regular-3;
    font-weight: $font-weight-bold;
    // letter-spacing: 0.125rem;  by RT
    padding-bottom: 1rem;
  }
  &__text {
    font-size: $font-size-regular-1;

    padding-bottom: 2.5rem;
  }
}
@media only screen and (max-width: 600px) {
  .features {
    &__item {
      //margin: 0 1rem;
      width: 95vw;
    }
  }
}
