@import "../../../scss/main";

.sapphire {
  background-color: $color-sapphire;
  color: $color-white;
  height: 100% ;
  padding-bottom: -100px;
  width: 100%;
 
  &__container {
    min-height: 60rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
  }
  &__leftSide {
    width: 35%;
    height: 80%;
    margin-top: 3rem;
    margin-left: 13.4rem;
  }
  &__heading {
    font-size: $font-size-large-4;
    font-weight: $font-weight-bold;
    letter-spacing: 0.1rem;
    z-index: 100;
    margin-top: 20rem;
  }
  &__text {
    padding-right: 1rem;
    font-size: $font-size-regular-3;
    padding-bottom: 2rem;
    letter-spacing: 0.1rem;
    margin-bottom: 4rem;
    margin-top: 1.2rem;
    z-index: 100;
  }
  &__info {
    font-size: $font-size-regular-4;
    letter-spacing: 0.1rem;
    width: 80%;
  }
  &__img {
    margin-top: 15rem;
    //margin-left: 536px;
    // width: 480rem;
    width: 65%;
    video {
      width: 100%;
      height: 100%;
    }
  }
  &__vertical{

    text-align: center;


  }
  &__vertical .vdiv{
  //  margin-top:23rem;
   align-items: center;
  //  height: 100vh;
  scroll-behavior: smooth;

  }
  &__vertical .vimg{
    width:10rem;
    margin: auto;
  }
  &__vertical .vtext{
    font-size: 3rem;
    margin-top: 5rem;

  }
  &__vertical .vtext p{
    margin-top: 2rem;
    @media only screen and (max-width: 800px) {
    margin-top: 1.5rem;
     
      }


  }
.sapphire__container{
  // padding-bottom:10rem;
  @media only screen and (max-width: 800px) {
  padding-bottom: 0;
 
  }
}

}
@media only screen and (max-width: 600px) {
  .sapphire {
    background-color:$color-sapphire;
    color: $color-white;
    height: 100%;
    padding-bottom: -100px;
    width: 100%;
    &__container {
      //min-height: 60rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    &__leftSide {
      width: 100%;
      height: 40%;
      margin-top: 0rem;
      margin-left: 0rem;
    }
    &__heading {
      font-size: $font-size-large-4;
      font-weight: $font-weight-bold;
      letter-spacing: 0.1rem;
      z-index: 1;
      text-align: center;
      margin-top: -20rem;
    }
    &__text {
      padding-right: 1rem;
      font-size: $font-size-regular-3;
      padding-bottom: 2rem;
      letter-spacing: 0.1rem;
      margin-bottom: 4rem;
      margin-top: 3rem;
      z-index: 100;
      text-align: center;
    }
    &__info {
      font-size: $font-size-regular-4;
      letter-spacing: 0.1rem;
      width: 80%;
      .myCarousel #infinite-carousel .InfiniteCarouselFrame {
        //width: 35rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .myCarousel #infinite-carousel .InfiniteCarouselFrame ul {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__img {
      margin-top: 0rem;
      //margin-left: 536px;
      // width: 480rem;
      width: fit-content;
      height: 40%;
      video {
        width: 100%;
        height: fit-content;
        //height: 40rem;
      }
    }
    &__vertical{

      text-align: center;
      padding-bottom:23rem;
  
    }
    &__vertical .vdiv{
     margin-top:23rem;
     align-items: center;
  
    }
    &__vertical .vimg{
      width:8rem;
      margin: auto;
    }
    &__vertical .vtext{
      font-size: 2.5rem;
      margin-top: 5rem;
      
  @media only screen and (max-width: 600px){
    font-size: 1.8rem;


  }
  
    }
  }
  .sapphire-transisting-heading-divison {
    width: 100%;
    //margin-left: 5rem;
    font-size: 0.5rem;
    text-align: center;
    //padding: 0rem 0.1rem;
    margin: 0rem;
  }
  .sapphire-transisting-heading {
    width: 98vw;
    padding: 2rem;
    line-height: 2.5rem;
    //margin-right: 10rem;
  }
}




.slider{
  // background-color: teal;
  // width: 100%;
  color: #fff;

  grid-template-columns: 100% 65%;
  position: relative;
  display: grid;
  height: 100vh;
  padding: 12rem;
  padding-left: 0;
  padding-right: 0;
 
  
  
  @media only screen and (max-width: 600px){
    padding: 40px 20px;
    margin-top: 200px;
    text-align: center;
  }
}


.slider .content_container{
  padding: 70px;
 
  
}

.slider .slides{
  overflow-y: scroll;
  max-height: 500px;
  // scrollbar-width: none;
  scrollbar-width: 1px;
  scroll-snap-type: y mandatory; //proximity
  overflow-y: scroll;
  text-align: center;
  scroll-behavior:auto ;  
  

}

.slider .slides::-webkit-scrollbar{
  display: none;
  

}



.slider .slides .slide{
  min-height: calc(100% - 80px);
  background-color: $color-sapphire;
  color: $color-white;
  display: flex;
  justify-content: center;
  // padding: 40px;
 
  text-align: center;
  scroll-snap-align: start;
  width: 100%;
  margin-bottom: 5rem;
  
 


  @media only screen and (max-width: 600px){
    padding: 10px;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5rem;


  }

  .vtext{
    font-size: 4rem;
    font-weight: 500;
    margin-top: 5rem;
    @media only screen and (max-width: 600px){
      font-size: 1rem;
      p{
        margin-top: 0.2rem;
      }
     
    }
  
   



  
    
  }
  .vimg{
    width:10rem;
    margin: auto;
  }
  


  align-items: center;
}



.vdiv{
height: 100vh;
padding-top: 20rem;
}