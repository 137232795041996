@import '../../scss/main';

.faq {
    color: $color-grey-dark-2;
    &__container {
        margin-bottom: 8rem;
    }
    &__item {
        border-bottom: $line-1;
    }
    &__header {
        background-color: transparent !important;
        padding: 0 !important;
        border-bottom: 0 !important;
        margin: 1.5rem 1rem;
        margin-left: 0;
        position: relative;
        cursor: pointer;
    }
    &__question {
        font-size: $font-size-large-1;
        // font-weight: $font-weight-bold;
        letter-spacing: 0.05rem;
        padding: 1rem 0;
        padding-right: 6rem;
    }
    &__answer {
        font-size: $font-size-regular-3;
        padding-left: 0 !important;
        padding-bottom: 1rem !important;
        letter-spacing: 0.02rem;
    }
    &__svg {
        position: absolute;
        margin-right: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
