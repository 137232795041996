table {
  font-family: open sans, sans-serif;
  border-collapse: collapse;
  width: 70%;
  text-align: center;
  padding-bottom: 0%;
  margin-bottom: 0%;
}

td,
th {
  border: 10px solid #ffffff;
  text-align: left;
  padding: 20px;
  font-weight: 600;
  color: #6b6b6b;
}

tr:nth-child(odd) {
  background-color: #dddddd;
  margin-bottom: -50rem;
  margin-top: -100px;
}

tr:nth-child(even) {
  border-top: 0px solid darkgreen;
}

.center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 20px;
}
.removeUpSpace {
  margin: 0%;
  padding: 0%;
  line-height: 0.15;
  border-top: 0px;
  background-color: white;
}

.buttonBasic {
  padding: 4% 8%;
  font-size: 100%;
  border-radius: 10px;
  color: white;
  background-color: #ffd654 !important;
  border-color: #ffd654 !important;
  margin: 4rem 0 !important;
  letter-spacing: 0.2rem !important;
  padding: 1rem 3rem !important;
}
.buttonPro {
  padding: 4% 8%;
  font-size: 100%;
  border-radius: 10px;
  color: white;
  background-color: #3ae2ed !important;
  border-color: #3ae2ed !important;
  margin: 4rem 0 !important;
  letter-spacing: 0.2rem !important;
  padding: 1rem 3rem !important;
}
.syp__buttonBasic,
.syp__buttonBasic:link,
.syp__buttonBasic:visited,
.syp__buttonBasic:hover,
.syp__buttonBasic:active {
  text-decoration: none;
  border-radius: 0.5rem;
  width: 190px;
  margin: 0.5rem 0;
  letter-spacing: 0.1rem;
  background-color: #ffd654 !important;
  border-color: #ffd654 !important;
  margin: 4rem 0 !important;
  letter-spacing: 0.2rem !important;
  padding: 1rem 3rem !important;
}
.syp__buttonPro,
.syp__buttonPro:link,
.syp__buttonPro:visited,
.syp__buttonPro:hover,
.syp__buttonPro:active {
  text-decoration: none;
  border-radius: 0.5rem;
  width: 190px;
  margin: 0.5rem 0;
  letter-spacing: 0.1rem;
  background-color: #ffd654 !important;
  border-color: #ffd654 !important;
  margin: 4rem 0 !important;
  letter-spacing: 0.2rem !important;
  padding: 1rem 3rem !important;
}
.bn {
  border-top: 0px;
  border-bottom: 0px;
  border-left: 10px solid white;
  border-right: 10px solid white;
}
.zeroBottom {
  margin-bottom: 10%;
  padding-bottom: 0%;
}
.table__button__basic {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5rem !important;
}

@media only screen and (max-width: 600px) {
  .bn {
    border-top: 0px;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
  }
  table {
    margin: 0rem 10rem;
  }
  .right-row {
    border-left: 3px solid white;
  }
  .pro-table {
    margin: 6rem 0rem;
  }
  td,
  th {
    border: 10px solid #ffffff;
    text-align: left;
    /*padding: 2rem;*/
    font-weight: 600;
    color: #6b6b6b;
  }
}
