@import '../../scss/main';

.terms {
    &__content {
        font-size: $font-size-regular-4;
        color: $color-grey-dark-2;
        margin-bottom: 8rem;
        letter-spacing: 0.02rem;
    }
    &__para {
        margin-bottom: 2rem;
  
    }
    &__head {
        margin: 4rem 0;
        margin-top: 6rem;
        font-size: $font-size-head;
        font-weight: $font-weight-bold;
    }
}
