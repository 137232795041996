@import "../../../scss/main.scss";

.tiy {
  &__heading {
    margin-top: 9rem;
    margin-bottom: 5rem;
  }
  &__supercontainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__container {
    min-height: 60rem;
    width: 90%;
    background-color: $color-grey-dark-1;
    color: $color-white;
    font-size: $font-size-large-5;
    font-weight: $font-weight-bold;
    letter-spacing: 2.5rem;
    margin-bottom: 7rem;
  }
  &__centerdiv {
    display:flex;
    justify-content:center; /* horizontally center */
    align-items:center;    /* vertically center */
  }
  &__centerdiv img{
    margin-top: 11rem;
    width: 10%;
  }
  &__tryontext {
    margin-top: 4rem;
    font-size: 2rem;
  }
  &__tryontext2 {
    margin-top: 1rem;
    font-size: 2rem;
  }
  &__centerdiv .trylink{
    margin-top: 3rem;
    width: 12rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-decoration: none;
    background-color: #3ae2ed;
    letter-spacing: 0.05rem;
    cursor: pointer;
    padding:1rem;
    text-align: center;
    margin-bottom:8rem;
    height: min-content;
    color: white;
    

  }
  &__centerdiv .trylink a{
    text-decoration: none;
    color: #fff;
  }
}

@media only screen and (max-width: 600px) {
  .tiy {
    &__supercontainer {
      display: flex;
      justify-content: center;
      align-items: center;
      //width: 100vw;
    }
    &__container {
      max-height: 50vw;
      width: 90vw;
      text-align: center;
      background-color: $color-grey-dark-1;
      color: $color-white;
      font-size: $font-size-large-4;
      font-weight: $font-weight-bold;
      letter-spacing: 2.5rem;
      margin-bottom: 7rem;
      margin-right: 0px;
    }
    &__centerdiv img{
      margin-top: 1.5rem;
      width: 10%;
    }
  }
}

.tiy__centerdiv{
  text-align: center;
}