@import "../../scss//main.scss";

.link {
  &--link {
    &:link,
    &:visited,
    &:hover &:active {
      background-color: transparent;
      text-decoration: none;
      border-radius: 0.5rem;
      padding: 1rem 1rem;
      margin: 0rem 0;
      letter-spacing: 0.2rem;
    }
  }

  &--fill {
    &:link,
    &:visited,
    &:hover &:active {
      text-decoration: none;
      border-radius: 0.5rem;
      padding: 1rem 2rem;
      margin: 0.5rem 0;
      letter-spacing: 0.1rem;
    }
  }

  &--redirect {
    &:link,
    &:visited,
    &:hover &:active {
      background-color: transparent;
      text-decoration: none;
      font-size: $font-size-regular-3;
      letter-spacing: 0.1rem;
    }
  }
  &--empty {
    &:link,
    &:visited,
    &:hover &:active {
      border: 0.25rem solid $color-primary;
      text-decoration: none;
      border-radius: 2rem;
      padding: 0.25rem 2rem;
      z-index: 100;
    }
  }

  &--color-primary {
    &:link,
    &:visited,
    &:hover &:active {
      color: $color-primary;
    }
  }
  &--color-secondary {
    &:link,
    &:visited,
    &:hover &:active {
      color: $color-secondary;
    }
  }
  &--color-white {
    &:link,
    &:visited,
    &:hover &:active {
      color: $color-white;
    }
  }
  &--color-light-grey {
    &:link,
    &:visited,
    &:hover &:active {
      color: $color-grey-light-2;
    }
  }
  &--color-dark-grey {
    &:link,
    &:visited,
    &:hover &:active {
      color: $color-grey-dark-3;
    }
  }
  &--color-transparent {
    &:link,
    &:visited,
    &:hover &:active {
      color: transparent;
    }
  }

  &--backgroundColor-primary {
    &:link,
    &:visited,
    &:hover &:active {
      background-color: $color-primary;
    }
  }
  &--backgroundColor-secondary {
    &:link,
    &:visited,
    &:hover &:active {
      background-color: $color-secondary;
    }
  }

  &--backgroundColor-transparent {
    &:link,
    &:visited,
    &:hover &:active {
      background-color: transparent;
    }
  }
}
