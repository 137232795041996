*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}


html,
body {
  box-sizing: border-box;
  font-size: 62.5% !important;
  font-family: "Open Sans", sans-serif !important;
  height: 100%;
  
 
}

a {
  text-decoration: none;
}

button,
input,
textarea {
  border: 0;
  &:focus {
    outline: none;
  }
}

ul,
ol {
  list-style: none;
}
