.error__supercontainer {
  width: 100%;
  height: 100%;
  padding: 2rem 5rem;
  margin: 10rem 0rem;
}
.error__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.error__textcontainer {
  width: 60%;
  height: 100%;
  padding-left: 7rem;
}
.error__textcontainer h4 {
  font-size: 8em;
  font-weight: 600;
  color: #3ae2ed;
}
.error__homepagelink {
  text-decoration: none;
}
.error__textcontainer p {
  letter-spacing: 0.05rem;
  font-size: 2.7em;
  font-weight: 600;
  margin-top: 3rem;
}
.error__redirectbutton {
  width: fit-content;
  font-size: 2rem;
  color: white;
  font-weight: 600;
  letter-spacing: 0.1rem;
  padding: 1.5rem;
  cursor: pointer;
  background-color: #3ae2ed;
  margin-top: 3rem;
  text-align: center;
}
.error__image {
  width: 40%;
  height: 100%;
  padding-right: 5rem;
}
@media only screen and (max-width: 600px) {
  .error__supercontainer {
    width: 100%;
    height: 100%;
    padding: 2rem 1rem;
    margin: 2rem 0rem;
  }
  .error__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .error__textcontainer {
    width: 100%;
    height: 100%;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  .error__textcontainer h4 {
    font-size: 5em;
    font-weight: 600;
    color: #3ae2ed;
  }
  .error__homepagelink {
    text-decoration: none;
  }
  .error__textcontainer p {
    letter-spacing: 0.05rem;
    font-size: 2.7em;
    font-weight: 600;
    margin-top: 3rem;
  }
  .error__redirectbuttonCont {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error__redirectbutton {
    width: 50%;
    font-size: 2rem;
    color: white;
    font-weight: 600;
    letter-spacing: 0.1rem;
    padding: 1.5rem;
    cursor: pointer;
    background-color: #3ae2ed;
    margin-top: 3rem;
  }
  .error__image {
    width: 100%;
    height: 100%;
    padding-right: 0rem;
    padding: 2rem;
  }
}
