@import "../../scss/main.scss";

.heading {
  text-align: center;
  margin: 5rem 0;
  margin-bottom: 7rem;
  color: $color-grey-dark-2;
  letter-spacing: 0.25rem;
  word-spacing: 0.4rem;
  font-weight: $font-weight-bold;
  font-size: $font-size-large-2;
}
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 3.5rem;
    letter-spacing: 0.2rem;
  }
}
