@import '../../../scss/main.scss';



.lit {
    &__heading {
        margin-top: 9rem;
        margin-bottom: 7rem;
    }
    &__uppertext{
        margin-top: 9rem;
        text-align: center;
        color: #2c2c2c;
        margin-bottom: 4rem;
        font-size: $font-size-large-2;
        letter-spacing: 0.1rem;
        font-family: 'Montserrat', sans-serif;
    }
    &__uppertext p{
        margin-top: 2.5rem;
    }
    &__link{
        width: 12rem;
        font-size: 1.5rem;
        font-weight: 600;
        text-decoration: none;
        background-color: #3ae2ed;
        letter-spacing: 0.05rem;
        cursor: pointer;
        padding:1rem;
        text-align: center;
        margin: auto;
        margin-bottom: 10rem;
    }
    &__link a{
        text-decoration: none;
        color: #fff;

    }
    &__bottomtext{
        text-align: center;
        font-size: 2rem;
        color: black;
        margin-top: 5rem;
    }
    &__ortext{
        text-align: center;
        font-size: 2rem;
        color: black;
        margin-top: 7rem;
        margin-bottom: 7rem;
    }
    &__subbox{
        border: 0.5rem solid black;
        width: 50%;
        margin:auto;
        margin-bottom: 10rem;
    }
    &__subscribe {
        padding: 1rem 0;
        margin-bottom: 1rem;
        &--input {
          width: 35rem;
          &::placeholder {
            color: black;
            letter-spacing: 0.075rem;
          }
          letter-spacing: 0.1rem;
          font-size: $font-size-regular-3;
          color: black;
          background-color:transparent;
          padding: 0.5rem 0;
          padding-bottom: 0.25rem;
          border-bottom: 0.25rem solid black;
        }
        &--button {
          &:link,
          &:visited,
          &:hover &:active {
            font-size: $font-size-regular-2;
            color: $color-white;
            border: 0.25rem solid black;
            text-decoration: none;
            border-radius: 2rem;
            padding: 0.5rem 2rem;
            letter-spacing: 0.075rem;
          }
        }
      }
}

@media only screen and (max-width: 600px) {
  .lit {
    &__heading {
        margin-top: 4rem;
        margin-bottom: 7rem;
    }
    &__uppertext{
        margin-top: 6rem;
        text-align: center;
        color: #2c2c2c;
        margin-bottom: 4rem;
        font-size: 3rem;
        letter-spacing: 0.1rem;
        font-family: 'Montserrat', sans-serif;
    }
    &__link{
        width: 12rem;
        font-size: 1.5rem;
        font-weight: 600;
        text-decoration: none;
        background-color: #3ae2ed;
        letter-spacing: 0.05rem;
        cursor: pointer;
        padding:1rem;
        text-align: center;
        margin: auto;
        margin-bottom: 10rem;
    }
    &__link a{
        text-decoration: none;
        color: #fff;

    }
    &__bottomtext{
        text-align: center;
        font-size: 2rem;
        color: black;
        margin-top: 5rem;
    }
    &__ortext{
        text-align: center;
        font-size: 2rem;
        color: black;
        margin-top: 7rem;
        margin-bottom: 7rem;
    }
    &__subbox{
        border: 0.5rem solid black;
        width: 50%;
        margin:auto;
        margin-bottom: 10rem;
    }
    &__subscribe {
        padding: 1rem 0;
        margin-bottom: 1rem;
        &--input {
          width: 35rem;
          &::placeholder {
            color: black;
            letter-spacing: 0.075rem;
          }
          letter-spacing: 0.1rem;
          font-size: $font-size-regular-3;
          color: black;
          background-color:transparent;
          padding: 0.5rem 0;
          padding-bottom: 0.25rem;
          border-bottom: 0.25rem solid black;
        }
        &--button {
          &:link,
          &:visited,
          &:hover &:active {
            font-size: $font-size-regular-2;
            color: $color-white;
            border: 0.25rem solid black;
            text-decoration: none;
            border-radius: 2rem;
            padding: 0.5rem 2rem;
            letter-spacing: 0.075rem;
          }
        }
      }

    
  }
}
.lit__link
{
  a:hover{
    color: white;

  }
}
.lit_uppertext_posi{
  text-align: start;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  .lit_mirror{

  }
}
.lit_txt{
 display: flex;
 flex-direction: column;
 height: max-content;
.lit_txt_head{
  margin: 5rem 0;
}

  
}